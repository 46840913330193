.directions-box {
  display: flex;
  flex: 1;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  gap: 20px;
  padding: 20px;
  padding-top: 0;
}

.directions-box .info-box {
  background-color: white;
  flex: 1;
  scroll-snap-align: start;
}

.directions-box .info-box__subdata {
  display: flex;
  justify-content: space-between;
}

.directions-box__container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.directions-box .label {
  margin-top: 5px;
  font-size: 14px;
  color: gray;
}

.directions-box .value {
  font-size: 18px;
  font-weight: bold;
}

.directions-box__button {
  padding: 15px;
  border: none;
  font-weight: bold;
  border-radius: 5px;
  font-size: 20px;
  user-select: none;
}

@media screen and (max-width: 800px) {
  .directions-box {
    gap: 10px;
    padding: 10px;
    padding-top: 0;
  }
}
