.progress-bar {
  height: 6px;
  width: 100%;
  background-color: var(--lightgray);
}

.progress-bar__bar {
  height: 100%;
  background-color: var(--darkgray);
  transition: width 200ms ease-in-out;
}
