/* @import '~leaflet/dist/leaflet.css'; */

:root {
  --black: #2e282a;
  --white: #ebebeb;
  --green: #ff4b3e;
  --lightgray: #d9dcd6;
  --darkgray: #6b717e;
}

html,
body,
#root,
.App {
  height: 100%;
}

.App {
  display: grid;
  grid-template-columns: minmax(300px, 400px) 1fr;
  grid-template-rows: max-content 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "search map"
    "navigation map";
}

.grid-search {
  grid-area: search;
  padding: 10px;
}

.grid-search .input-container {
  border-radius: 25px;
  border: 1px solid gray;
  display: flex;
  align-items: center;
}

.grid-search .input-container:focus-within {
  border: 1px solid black;
  box-shadow: 0 0 1px black;
}

.grid-search input {
  border-radius: 25px;
  font-size: 16px;
  border: none;
  outline: none;
  flex: 1;
  padding: 10px 20px;
  padding-right: 0;
}

.grid-search button {
  border: none;
  background: none;
  font-weight: bold;
  font-size: 22px;
  padding: 2px;
  color: black;
  margin: 10px;
  height: 19px;
  margin-top: -3px;
}

.grid-navigation {
  grid-area: navigation;
  display: flex;
  flex-direction: column;
}

.grid-map {
  grid-area: map;
}

.grid-searchresults {
  grid-area: navigation;
  background-color: white;
  z-index: 3; /* above the map, their logo uses z-index: 2; */
  overflow-y: scroll;
}

.grid-searchresults ul {
  list-style-type: none;
  padding: 0;
}

.grid-searchresults li {
  border-bottom: 1px solid gray;
  padding: 10px;
}
.grid-searchresults li:first-of-type {
  border-top: 1px solid gray;
}
.grid-searchresults li .search-result__title {
  font-weight: bold;
}
.grid-searchresults li .search-result__subtitle {
  color: gray;
}

@media screen and (max-width: 500px) {
  .App {
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr max-content;
    grid-template-areas:
      "search"
      "map"
      "navigation";
  }

  .grid-searchresults {
    grid-row-start: 2;
    grid-row-end: span 2;
  }
}

/* Sorry, but I don't want people to think they're improving the path map */
.mapbox-improve-map {
  display: none;
}

.info-text {
  margin-top: 5px;
  font-size: 14px;
  color: gray;
}

.sidebar {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (max-width: 800px) {
  .sidebar {
    gap: 10px;
  }
}
